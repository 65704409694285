.post-author {
    font-size: 12px;
}

.post-content img {
    display: flex;
    max-width: 1134px;
    max-height: 406px;
    margin-top: 15px;
    margin-bottom: 15px;
    // TODO: Experiment with the object-fit, if it's ok
    object-fit: cover;
}

.author-card {
    border-radius: 4px;
    background-color: #33253f !important;
    border: 1px solid rgba(142, 126, 162, 0.3);
}

.author-img img {
    border-radius: 50%;
}

.article {
    &_content {
        &-old {
            img {
                display: flex;
                max-width: 1134px;
                max-height: 406px;
                margin-top: 15px;
                margin-bottom: 15px;
                // TODO: Experiment with the object-fit, if it's ok
                object-fit: cover;

                @include media-breakpoint-down(md) {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            /* Alternative to below, would be to create a div block with border-left, but below might be easier to work with BE */
            h2 {
                padding-top: 10px;
                padding-bottom: 10px;
                margin-bottom: 20px;
                margin-top: 30px;

                &:before {
                    content: '';
                    border-left: 1px solid $brand-yellow;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin-right: 25px;
                }
            }
        }

        &_header {

            &-level-2 {
                padding-top: 10px;
                padding-bottom: 10px;
                margin-top: 30px;
                margin-bottom: 30px;
                color: $brand-yellow;
                font-style: italic;

                border-left: 1px solid $brand-yellow;
                padding-left: 20px;
            }
        }

        &_code {
            background-color: #33253f;
            border: 1px solid rgba(142, 126, 162, 0.3);
            border-radius: 4px;
            padding: 10px;
            margin-top: 10px;
            margin-bottom: 10px;

            p:last-child {
                margin-bottom: 0;
            }
        }

        &_quote {
            @include jost-font;
            font-size: 1.2em;
            width: 60%;
            margin: 50px auto;
            color: white;
            padding: 1.2em 30px 1.2em 75px;
            line-height: 1.6;
            position: relative;
            background-color: #33253f;
            border: 1px solid rgba(142, 126, 162, 0.3);
            border-left: 8px solid $brand-blue;

            @include media-breakpoint-down(md) {
                width: 100%;
                margin: 20px auto;
            }

            &-align-left {
                text-align: left;
            }

            &-align-center {
                text-align: center;
            }

            &::before {
                font-family: Arial;
                content: "\201C";
                color: $brand-blue;
                font-size: 4em;
                position: absolute;
                left: 10px;
                top: -10px;
            }

            span {
                display: block;
                color: white;
                font-style: normal;
                font-weight: bold;
                margin-top: 1em;
            }
        }

        &_list {
            &-style-unordered {
                list-style-type: none;
                margin-top: 10px;
                margin-bottom: 10px;

                // Add yellow circle to list items
                li {
                    &:before {
                        content: '';
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background-color: $brand-yellow;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }
            }

            &-style-ordered {
                list-style-type: none;
                margin-top: 10px;
                margin-bottom: 10px;

                // Add the color yellow to the number
                li {
                    counter-increment: item;

                    &:before {
                        content: counter(item) '.';
                        color: $brand-yellow;
                        margin-right: 10px;
                    }
                }
            }
        }

        &_paragraph {
            .inline-code {
                background: rgba(250, 239, 240, 0.78);
                color: #b44437;
                padding: 3px 4px;
                border-radius: 5px;
                margin: 0 1px;
                font-family: inherit;
                font-size: 0.86em;
                font-weight: 500;
                letter-spacing: 0.3px;
            }

            .cdx-marker {
                background: lighten(#33253f, 30%);
                padding: 3px 0;
                border-radius: 5px;
            }
        }

        &_image {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        &_table {
            background-color: #33253f !important;
            border: 1px solid rgba(142, 126, 162, 0.3);

            thead {
                th {
                    color: $brand-yellow !important;
                }
            }

            > tbody {
                > tr:nth-child(odd) > * {
                    color: darken(white, 10%);
                }

                > tr {
                    &:hover > * {
                        color: darken(white, 10%);
                    }
                }
            }
        }
    }

    &_by {
        &_user {
            a {
                text-transform: capitalize;
                color: $brand-yellow;
                text-decoration: none;
            }
        }
    }

    &_author {
        border-radius: 4px;
        background-color: #33253f !important;
        border: 1px solid rgba(142, 126, 162, 0.3);
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 10px;

        &_by {
            &_header {
                a {
                    font-weight: 700;
                    color: $brand-yellow;
                    text-transform: capitalize;

                    &:hover {
                        color: darken($brand-yellow, 10%);
                    }
                }
            }
        }
    }
}
