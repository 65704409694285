.privacy,
.terms-conditions {
    padding-top: 20px;
    padding-bottom: 20px;

    &_content {
        &_section {
            margin-bottom: 40px;
        }

        &_header {
            font-family: $oxanium-family;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 38px;
            text-transform: capitalize;
            color: $brand-yellow;
        }

        &_subheader {
            font-family: $oxanium-family;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            text-transform: capitalize;
            color: $brand-yellow;
        }

        &_paragraph {
            text-align: justify;
            text-transform: capitalize;

            color: #FFFFFF;

            &-highlight {
                color: $brand-yellow;
            }
        }

        &_list {
            color: #FFFFFF;

            li {
                list-style: none;
                margin-bottom: 10px;
                font-family: $jost-family;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                /* or 162% */

                text-align: justify;
                text-transform: capitalize;

                color: #FFFFFF;

                &::before {
                    background-color: $brand-blue;
                    border-radius: 50%;
                    content: "";
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: 2px;
                    height: 10px;
                    width: 10px;
                }
            }
        }
    }
}
