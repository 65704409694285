.portal-confirm-action-modal {
    .modal-dialog {
        max-width: 350px;
        height: 553px;
    }
    .modal-content {
        background-color: #1A1D21;
        border: none;
    }

    &_content {
        text-align: center;
        margin-top: 15%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        &_header {
            font-weight: 600;
            font-size: 24px;
            line-height: 35px;
        }

        &_text {
            font-weight: 300;
            &-highlight {
                color: $danger-red;
            }
        }

        &_buttons {
            margin: 10px auto;
            width: 50%;
            display: flex;
            flex-direction: column;
            row-gap: 15px;
        }
    }
}
