.home-faq {
    .faq {
        margin-bottom: 20px;
    }
}

.faq {
    &_card {
        background: radial-gradient(144% 144% at 49.86% 50%, #20132B 32.81%, #713B09 100%);
        border: 1px solid #533F64;
        border-radius: 4px;
        margin: auto;
        display: flex;
        padding: 10px;
        align-items: center;
        min-height: 115px;

        @include media-breakpoint-between(md, lg) {
            min-height: 150px;
        }

        .content-wrapper {
            width: 100%;
        }
    }

    &_body {
        padding-right: 20px;
        padding-top: 10px;
        line-height: 28px;
    }

    &_title {
        width: 100%;

        &-highlight {
            color: var(--zx-text-color-yellow);
        }
    }

    &_btn {
        background: unset;
        border-radius: unset;
        border: unset;
        color: var(--zx-default-white);
    }
}
