.auth-verification {
    padding: 20px;
    text-align: center;

    &_loading {
        .spinner-border {
            width: 4rem;
            height: 4rem;
        }
    }

    p {
        font-size: 20px;
    }
}
