.add-server {
    &_form {
        margin-top: 40px;
    }
}

.details-server,
.logo-server,
.add-server {
    padding: 20px;
    padding-top: 40px;

    @include media-breakpoint-down(md) {
        padding: 15px;
        padding-top: 20px;
    }

    &_header {
        font-size: 20px;
        line-height: 29px;
        font-weight: 600;
        color: white;
    }

    &_subheader {
        font-size: 14px;
        line-height: 20px;
        color: white;
    }

    &_buttons {
        display: flex;
        justify-content: flex-end;
        column-gap: 20px;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }
}

.logo-server {
    &_logo {
        background: rgba(10, 8, 74, 0.1);
        border-radius: 4px;
        height: 200px;
        width: 100%;
        padding: 10px;

        @include media-breakpoint-down(md) {
            padding-left: 0px;
            padding-right: 0px;
        }

        &_content {
            border: 1px dashed #C4C4C4;
            border-radius: 20px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 30px;

            &-dragging {
                border-color: $brand-yellow;
            }

            &_image {
                background: white;
                height: 120px;
                width: 120px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                @include media-breakpoint-down(md) {
                    height: 80px;
                    width: 80px;
                }

                &_svg {
                    color: $brand-yellow;
                    font-size: 42px;
                    transform: translate(0px, -11%);

                    @include media-breakpoint-down(md) {
                        font-size: 32px;
                    }
                }
            }

            &_text {
                width: 30%;
                text-align: center;
                padding-top: 10px;
                display: flex;
                flex-direction: column;

                @include media-breakpoint-down(md) {
                    width: 60%;
                }

                &_header {

                    color: #909399;
                }

                &_subheader {

                    color: $brand-blue;
                    margin-top: 10px;
                    cursor: pointer;
                }

                &_file {
                    &_remove {
                        cursor: pointer;
                        color: $brand-blue;
                        font-size: 14px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.details-server {
    &_terms-of-use {
        width: 100%;
        background: rgba(10, 8, 74, 0.1);
        border-radius: 4px;
        padding: 20px;

        &_header {

            font-size: 20px;
            line-height: 29px;
            font-weight: 600;
            color: white;
        }
    }

    &_form {
        margin-top: 20px;

        &_inputs {
            .col-12 {
                margin-top: 20px;
            }
        }
    }
}

.success-server {
    &_content {
        margin-top: 10%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        &_title {
            display: flex;
            justify-content: center;
            flex-direction: column;

            img {
                margin: auto;
            }

            &_header {

                font-weight: 700;
                font-size: 36px;
                line-height: 24px;
                text-align: center;
                margin-top: 30px;
            }

            &_subheader {

                font-weight: 400;
                font-size: 16px;
                line-height: 30px;
                text-align: center;
            }
        }

        &_buttons {
            margin-top: 35px;
            text-align: center;
        }
    }
}
