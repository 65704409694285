.portal-layout {
    background: #1A1D21;

    &_content {
        padding-left: 250px;

        @include media-breakpoint-down(md) {
            padding-left: 0px;
            margin-top: 75px;
        }

        &_inner {
            min-height: calc(100vh - 36px);
        }
    }
}
