.comment-btn {
    padding: 3px 11px;
    font-size: 12px;
    border-radius: 30px;
    border: 0;
    color: white;
}

.comment-blue {
    background-color: rgba(2, 240, 255, 0.25);
}

.comment-yellow {
    background: rgba(255, 229, 2, 0.25);
}

.comment-date {
    font-size: 12px;
}

h5.fs-6.comment-username {
    margin-bottom: 0;
}

.comment-card {
    background-color: #1F122C;
    border-radius: 20px;
}

.comment-description {
    line-height: 25px;
}

/* To prevent default styling of the button */
.comment-reply-btn {
    background-color: unset;
    border: unset;
}

.vote-amount.negative {
    color: #ca3636;
}
.vote-amount.positive {
    color: #529023;
}
