.post-placeholder {
    color: #8e7ea1;
}

.padding-left-voting {
    padding-left: 20px;
}

.padding-card {
    padding: 20px 20px 10px 20px;
}

.review {
    background: #1f1226;
    border: 1px solid #362d42;
    border-radius: 10px;

    &-has-replied {
        margin-bottom: 15px !important;
    }

    &-reply-response {
        margin-left: 10%;
    }

    &_header {
        display: flex;
        justify-content: space-between;

        &_title {
            flex: 1;

            h3 {
                font-size: 25px;
                font-weight: 500;
                line-height: 36px;
                margin-bottom: 0px;
            }

            &_stars {
                margin-top: -5px;

                i {
                    font-size: 20px;
                }
            }

            &_author {
                margin-top: 10px;

                &-reply {
                    margin-top: 0px;
                }

                &_by {
                    color: #4c4151;
                }

                &_avatar {
                    position: relative;
                    top: 5px;

                    img {
                        vertical-align: initial;
                    }
                }

                &_name {
                    color: $brand-blue;
                    text-decoration: none;
                }
            }
        }

        &_voting {
            display: flex;
            column-gap: 20px;

            @include media-breakpoint-between(xs, sm) {
                flex-direction: column;
            }

            &_vote {
                color: #8e7ea2;

                &_icon {
                    font-size: 23px;
                    margin-right: 5px;
                    cursor: pointer;

                    &-voted {
                        color: $brand-yellow;
                    }

                    &:hover {
                        color: $brand-yellow;
                    }
                }

                &_text {
                    font-size: 14px;
                }
            }
        }
    }

    &_body {
        margin-top: 12px;

        p {
            margin-bottom: 0px;
        }
    }

    &_footer {
        margin-top: 15px;
        padding-top: 10px;
        border-top: 1px solid #362d42;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_actions {
            display: flex;
            gap: 15px;

            &_report {
                color: $brand-yellow;
                cursor: pointer;

                &:hover {
                    color: darken($brand-yellow, 10%);
                }
            }

            &_reported {
                color: $brand-yellow;
                opacity: 0.5;
            }

            &_reply {
                color: $brand-blue;
                cursor: pointer;

                &:hover {
                    color: darken($brand-blue, 10%);
                }
            }
        }

        &_date {
            text-align: right;
            color: #4c4151;

            &_text {
                &-highlight {
                    color: $brand-blue;
                }
            }
        }
    }
}

.review-reply {
    background: #1f1226;
    border: 1px solid #362d42;
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    margin-left: 10%;
    margin-bottom: 30px;

    &_footer {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid #362d42;
    }
}
