.register {
    padding-top: 50px;

    &_header {
        font-family: $oxanium-family;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 0.03em;
        color: #FFFFFF;
        margin-bottom: 20px;

        &-highlight {
            background: linear-gradient(180deg, #02F0FF 0%, #0057FF 100%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }

    &_button {
        width: 100%;
    }

    &_already-registered {

        margin-top: 20px;

        color: #FFFFFF;

        a {
            color: $brand-blue;
            text-decoration: none;
        }
        a:hover {
            color: $brand-blue-hover;
        }
    }

    &_agree {

        color: #A1A7A8;
    }

    &_terms-conditions {

        color: #00AEFF;
        text-decoration: none;
    }

    &_image {
        display: flex;
        justify-content: center;
        position: relative;

        &_image {
            position: absolute;

            @include media-breakpoint-down(lg) {
                top: 0px;
                width: 341px;
                height: 348px;
            }
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
