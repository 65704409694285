.popular-posts {
    margin-bottom: 0px;

    .popular-post {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}

.popular-post {
    &_content {
        display: flex;
        column-gap: 10px;
        margin-bottom: 10px;

        &_image {
            &:hover {
                @include transition-brightness-122;
                cursor: pointer;
            }

            &_image {
                // border-radius: 5px;
                object-fit: fill;
            }
        }

        &_text {
            text-align: left;
            width: 75%;

            &_title {
                a {
                    color: white;
                    text-decoration: none;

                    &:hover {
                        color: $brand-yellow;
                    }
                }
            }

            &_date {
                &_date {
                    color: #9D9D9D;
                    font-size: 14px;
                }

                &_icon {
                    font-size: 18px;
                }
            }
        }
    }
}
