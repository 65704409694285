.view-addon,
.view-server {
    position: relative;
    z-index: 2;
    min-height: 200px;
    padding-bottom: 60px;

    &_info {
        background: #1B1024;
        border: 1px solid rgba(142, 126, 162, 0.3);
        box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.39);
        border-radius: 4px;
        top: -45px;
        position: relative;
        width: 100%;
        padding: 25px;

        &-featured {
            border: 1px $brand-blue solid;
        }

        &_header {
            margin-top: 20px;
            @include oxanium-font;
            font-weight: 600;

            display: flex;
            flex-wrap: wrap;
            row-gap: 15px;
            justify-content: space-between;
            align-items: center;

            &_title {
                display: flex;
                align-items: center;
                column-gap: 20px;

                h1 {
                    font-size: 25px;
                }

                &_expansions {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;

                    &_expansion {
                        @include inter-font;
                        font-size: 14px;
                        text-decoration: none;
                        font-weight: initial;
                        color: white;
                        line-height: 17px;

                        background: rgba(0, 192, 254, 0.15);
                        border-radius: 32px;
                        padding: 10px;
                        text-align: center;
                        border-color: #1B1024;
                    }
                }
            }

            &_actions {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                gap: 15px;

                @include media-breakpoint-down(md) {
                    justify-content: initial;
                }
            }
        }

        &_stats {
            margin-top: 20px;
            display: flex;
            gap: 15px;

            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
            }

            &_item {
                width: 204px;
                height: 75px;
                background: #5C4369;
                /* border: 1px solid #B6B5B6; */
                border-radius: 4px;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                &-primary {
                    background: $brand-blue;
                }

                &_content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }

                span,
                h2 {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 29px;
                    margin-bottom: 0px;
                }

                h2 {
                    font-weight: 400;
                }

                &_title {
                    h2 {
                        font-size: 16px;
                    }
                }

                &_value {
                    span {
                        text-transform: capitalize;
                        font-size: 20px;
                    }
                }
            }
        }
    }

    &_description {
        &_title {
            h2 {
                @include oxanium-font;
                font-weight: 700;
            }
        }

        &_content {
            word-wrap: break-word;
        }

        &_actions {
            margin-top: 20px;

            &_play {
                display: flex;
                align-items: center;
                column-gap: 5px;
            }
        }
    }

    &_reviews {
        &_title {
            margin-bottom: 35px;

            h2 {
                @include oxanium-font;
                font-weight: 700;
                font-size: 25px;
            }

            &_info {
                display: flex;
                column-gap: 10px;

                span {

                    font-size: 20px;
                }

                // Star ratings
                i {
                    font-size: 18px;
                }
            }
        }

        &_reviews {
            .review {
                margin-bottom: 30px;
            }
        }

        &_sign-in {
            text-align: center;

            p {
                margin-bottom: 0px;
                font-size: 18px;

                a {
                    color: $brand-blue;
                    text-decoration: none;
                }
                a:hover {
                    color: $brand-blue-hover;
                }
            }
        }

        &_leave-review {
            background: #1B1024;
            border: 1px solid rgba(142, 126, 162, 0.3);
            border-radius: 4px;
            width: 100%;
            padding: 25px;

            &_title {
                h2 {
                    @include oxanium-font;
                    font-weight: 700;
                }
            }
        }
    }
}

.report-server-review-modal,
.download-addons {
    .modal-content {
        background: #1B1024;
        border: 1px solid rgba(142, 126, 162, 0.3);
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    &_button {
        background: #5C4369;
        color: white;
        width: 100%;
        text-transform: capitalize !important;

        &:hover {
            background: lighten(#5C4369, 10%);
        }
    }
}

.report-server-review-modal {
    .modal-content {
        padding: 0px;
    }
}
