.system-bar {


    height: 60px;
    background: linear-gradient(90deg, #190424 38.4%, #00408A 100%);
    color: white;

    &_inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-down(sm) {
            justify-content: flex-end;
        }
    }

    &_login_account {
        cursor: pointer;
    }

    &_info {
        font-weight: 500;
        @media (min-width: 576px) and (max-width: 993px) {
            display: none;
        }
    }

    .discord-icon {
        line-height:1px;
        position: relative;
        padding: 0.5rem;
        /*background: linear-gradient(90deg, #190424 38.4%, #00408A 100%);*/
        border: 1px solid rgba(235,235,255,.05);
        border-radius: 50%;
        margin-left: 7px;
    }

    .discord-icon:hover {
        border-color: #004ca3
    }

    .search-bar .search-bar-child {
        position: relative;
        z-index: 999
    }

    .search-bar .search-bar-child input {
        height: 45px;
        background: #1b1024;
        border: 1px solid rgba(235,235,255,.05);
        border-radius: 0.75rem;
        color: #fff;
        padding: 0 40px 0 55px;
        width: 600px;
    }

    .form-control.w-100.search-input {
        transition: all 0.3s ease-in-out;
    }

    .form-control.w-100.search-input:hover {
        -webkit-filter: brightness(125%);
        filter: brightness(125%);
    }

    .search-bar .search-bar-child .search-options {
        max-width: 100px;
        background: transparent;
        top: 50%;
        right: 3px;
        transform: translateY(-50%);
    }

    .search-bar .search-bar-child .head_search_mobi_close {
        display: none;
        position: absolute;
        line-height: 1;
        cursor: pointer;
        padding: 5px;
        color: #ebebeb;
        top: 50%;
        right: 3px;
        transform: translateY(-50%);
        background-color: rgba(255,255,255,.14);
        border-radius: 50%;
    }
        transition: opacity 0.5s ease-in-out;

    .search-bar .search-bar-child .search-options button {
        background: #312f46ad;
        border: none;
        color: #b2b2b2;
        border-radius: 0.75rem;
        /* height: 50%; */
        height: 38px;
    }

    .bootstrap-select.form-control {
        margin-bottom: 0;
        padding: 0;
        border-radius: 0;
        border: none;
        height: auto;
    }

    .bootstrap-select>select {
        position: absolute!important;
        bottom: 0;
        left: 50%;
        display: block!important;
        width: 0.5px!important;
        height: 100%!important;
        padding: 0!important;
        opacity: 0!important;
        border: none;
        z-index: 0!important;
    }

    .bootstrap-select>.dropdown-toggle {
        position: relative;
        width: 100%;
        text-align: right;
        white-space: nowrap;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    :not(.input-group)>.bootstrap-select.form-control:not([class*=col-]) {
        width: 100%;
    }

    .search-bar .search-bar-child>svg {
        position: absolute;
        pointer-events: none;
        top: 50%;
        left: 17px;
        transform: translateY(-50%);
        color: #b2b2b2;
    }

    .search-bar {
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }

    .inner.show.dropdown-inside {
        max-height: 296px;
        overflow-y: auto;
        min-height: 0px;
    }

    button.btn.btn-primary.dropdown-toggle.btn-search {
        outline: none !important;
        box-shadow: none;
    }

    button.btn.btn-primary.dropdown-toggle.btn-search:hover {
        -webkit-filter: brightness(120%);
        filter: brightness(120%);
    }

    .dropdown_menu_item.search {
        display: none;
        opacity: 1;
    }

    .dropdown_menu_item.search-result {
        display: none;
        opacity: 1;
    }

    .dropdown_menu_item.show {
        display: block;
        border-radius: 6px;
        border: 1px solid #414141;
        transition: opacity 0.1s ease-in-out;
    }

    .dropdown_menu_item.show.yay {
        display: none
    }
    /* Dropdown search-result related */
    .dropdown_menu_item.search-result {
        top: 43px;
    }

    &_login {
        display: flex;
        align-items: center;
        font-weight: 300;

        &_splitter {
            margin-right: 10px;
            margin-left: 10px;
        }

        a {
            cursor: pointer;
            text-decoration: none;
            color: white;

            &:hover {
                color: var(--zx-default-blue-hover);
            }
        }
    }

    &_profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
        cursor: pointer;
        position: relative;
    }

    &_icon {
        margin-right: 5px;
    }
}
