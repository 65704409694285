.home-filter {
    position: relative;
    z-index: 1;
    padding-top: 20px;

    &-offset {
        top: -100px;
        padding-top: initial;
        margin-bottom: -100px;

        @include media-breakpoint-down(xl) {
            top: -73px;
        }

        @include media-breakpoint-down(md) {
            top: 20px;
            margin-bottom: 0px;
        }
    }

    &_filters {
        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }
    }

    &_content {
        &_header {
            text-align: center;
            font-size: 30px;
        }

        &_inner {
            &_more-features {
                &_title {
                    color: $brand-yellow;
                }
            }
        }
    }

    &_button {
        font-size: 18px !important;
        transition: all 0.3s ease-in-out;
    }

    &_button:hover {
        -webkit-filter: brightness(105%);
        filter: brightness(105%);
    }

    /* For the input fields when selecting more features */
    .filter-input input {
        transition: all 0.3s ease-in-out;
    }
    .filter-input input:hover {
        -webkit-filter: brightness(125%);
        filter: brightness(125%);
    }

    .filter-input {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .filter-input:hover{
        -webkit-filter: brightness(125%);
        filter: brightness(125%);
    }

    .outer-container {
        background: #1b1024;
        border: 1px solid rgba(142, 126, 162, 0.3);
        box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.39);
        border-radius: 4px;
        padding: 0 20px 0 20px;
        cursor: default;

        /* Checkbox custom */
        /* Customize the label (the container) */
        .container {
            display: block;
            position: relative;
            /*padding-left: 35px;*/
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }

    .filter-headline {
        font-weight: 600;
        font-family: $oxanium-family;
    }

    /* Hide the browser's default checkbox */
    label.container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border: 1px solid #ffcc00;
        border-radius: 4px;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        background-color: #1b1024;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-color: #1b1024;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 4.5px;
        top: 4.5px;
        width: 60%;
        height: 60%;
        border-radius: 4px;
        background-color: var(--zx-default-yellow);
    }
}
