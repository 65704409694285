.favourite-servers {
    padding: 30px;

    .spinner-border {
        width: 3rem;
        height: 3rem;
    }

    &_loader {
        display: flex;
        justify-content: center;
    }
}
