.form-control {
    background-color: #1b1024;
    border: 1px solid $input-border-line-color;
    border-radius: 4px;
    padding: 17px;
    color: white;

    &::placeholder {
        color: #A1A7A8;
    }

    &:focus {
        background-color: #1b1024;
        color: white;
        border-color: white;
        box-shadow: none;
    }

    &:disabled {
        opacity: 0.5;
    }
}

.form-check {
    &-input {
        position: relative;
        height: 25px;
        width: 25px;
        border: 1px solid $input-border-line-color;
        border-radius: 4px;
        background-color: #1b1024;

        &:focus,
        &:checked {
            border-color: #ffcc00;
            box-shadow: none;
        }

        &:checked {
            background-color: initial;

            &:after {
                content: "";
                position: absolute;
                left: 4.5px;
                top: 4.5px;
                width: 60%;
                height: 60%;
                border-radius: 4px;
                background-color: var(--zx-default-yellow);
            }
        }

        &:checked[type=checkbox] {
            background-image: none;
        }
    }

    &-label {
        color: white;
        margin-top: 3.5px;
        margin-left: 10px;
    }
}

.form-select {
    background-color: #1b1024;
    color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 1px solid $input-border-line-color;
    border-radius: 4px;
    padding: 17px;

    &:focus {
        background-color: #1b1024;
        color: white;
        border-color: white;
        box-shadow: none;
    }

    &::placeholder {
        color: #A1A7A8;
    }
}

.react-select-container {
    &.is-invalid {
        .react-select__control {
            border-color: #dc3545 !important;
        }
    }

    .react-select__control {
        background-color: #1A1D21;
        color: white;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border: 1px solid $input-border-line-color;
        border-radius: 4px;
        padding: 10px;
        height: 60px;

        &.is-invalid {
            border-color: #dc3545 !important;
        }

        &:hover,
        &--is-focused {
            border-color: white;
            box-shadow: none;
        }
    }

    .react-select__menu {
        background-color: #1A1D21;
    }

    .react-select__option {
        &:focus,
        &:visited,
        &--is-focused,
        &:hover {
            background: lighten(#1A1D21, 10%);
        }
    }

    .react-select__multi {
        &-value {
            background-color: #1A1D21;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            border: 1px solid $input-border-line-color;

            &__label {
                color: white;
            }
        }
    }
}

.portal-layout {
    .form-control {
        background: #1A1D21;
    }

    .form-check {
        &-input {
            background: #1A1D21;
        }
    }

    button {
        .spinner-border {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
}
