@mixin jost-font {
    font-family: $jost-family;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.03em;
}

@mixin teko-font {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

@mixin inter-font {
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
}

@mixin oxanium-font {
    font-family: $oxanium-family;
    font-style: normal;
    font-size: 25px;
    line-height: 31px;
}
