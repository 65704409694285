.advertising {
    padding-bottom: 40px;

    &_card {
        border-radius: 20px;
        background: linear-gradient(183.17deg, #04103C 2.62%, #022D3A 217.35%);
        border-radius: 20px;
        position: relative;
        padding-top: 40px;
        margin-bottom: 20px;
        height: 100%;

        &::after {
            content: "";
            position: absolute;
            top: 0px;
            pointer-events: none;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            background: url('/images/advertising/card-background.png');
        }

        &_header {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &_title {
                font-size: 32px;
            }

            &_description {
                text-align: center;
                max-width: 400px;
            }
        }

        &_banner {
            background: rgba(2, 179, 255, 0.3);
            text-align: center;
            padding: 8px;

            &_title {
                color: $brand-yellow;
                @include inter-font;
                font-size: 42px;
                margin-bottom: 0px;

                &_currency {
                    color: white;
                }
            }

            &_description {
                margin-bottom: 0px;
                font-size: 18px;
            }
        }

        &_body {
            &_list {
                list-style: none;
                margin-top: 20px;

                &_item {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    font-weight: 500;

                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }

            &_action {
                display: flex;
                justify-content: center;
                margin-top: 20px;

                &_button {
                    width: 202px !important;
                    height: 40px !important;
                }
            }

            &_description {
                padding: 20px;
                text-align: center;
            }
        }
    }

    &_content {
        &_text {
            color: #D9D9D9;
        }
    }

    &_features {
        &_item {
            display: flex;
            align-items: center;
            background: rgba(121, 121, 121, 0.09);
            border: 1px solid #5F5F5F;
            border-radius: 12px;
            margin-top: 15px;
            padding: 10px;

            &_icon {
                margin-top: 5px;
                width: 5%;

                @include media-breakpoint-between(xs, sm) {
                    width: 15%;
                }
            }

            &_content {
                width: 95%;
                h3 {
                    margin-bottom: 0px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #B1B1B1;
                    line-height: 29px;
                }

                @include media-breakpoint-between(xs, sm) {
                    width: 85%;
                }
            }
        }
    }
}
