.toc-list {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #646464;
    border-radius: 4px;
}

.toc-list-item {
    display: block;
    padding: 10px;
    margin: 0;
    border-bottom: 1px solid #646464;
}

.toc-list-item:last-child {
    border-bottom: none;
}

.toc-button {
    position: relative;
    display: block;
    background: none;
    border: none;
    padding: 0 15px 0 0;
    margin-bottom: 15px;
    cursor: pointer;
    color: #ff8f10;
}

.toc-button:after {
    position: absolute;
    top: 8px;
    right: 0px;
    content: "";
    border: solid #ff8f10;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.toc-button-open:after {
    top: 8px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
.toc-list-hidden {
    display: none;
}
