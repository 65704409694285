.pagination {
    // Bootstrap adds flex to this class, but we want to center it
    justify-content: center;

    &_content {
        display: flex;
        justify-content: center;
        align-items: center;

        &_arrow {
            cursor: pointer;
            font-size: 22px;
            color: #808080;

            &:hover {
                color: #FFC702;
            }
        }

        &_items {
            &_item {
                background-color: #1B1024;
                color: #A3A3A3 !important;
                border: 1px solid #8E7EA2 !important;
                border-radius: 2px;
                text-align: center;
                padding: 8px 10px 8px 10px;
                font-family: $jost-family;
                margin-right: 1rem;

                &:last-child {
                    margin-right: 0;
                }


                &:focus,
                &:hover,
                &-active {
                    background: #FFC702;
                    border: 1px solid #FFC702;
                    border-radius: 2px;
                    color: white !important;
                }
            }
        }
    }
}
