.profile {
    &_header {
        position: relative;

        &_avatar {
            display: flex;
            column-gap: 30px;

            &_avatar {
                border-radius: 50%;
                border: 5px solid #1D1024;
                height: 192px;
                width: 192px;
                position: relative;
                top: -80px;
            }

            &_info {
                padding-top: 20px;

                &_label {
                    display: flex;
                    column-gap: 20px;

                    &_info {
                        width: 100px;
                    }
                }

                &_highlight {
                    color: $brand-yellow;
                }
            }
        }

        &_info {
            margin-top: -50px;

            @include media-breakpoint-down (sm) {
                margin-top: -70px;
            }

            &_username {
                &_title {
                    font-size: 28px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }

            &_badge {
                margin-top: 20px;
                margin-bottom: 30px;

                background: linear-gradient(91.86deg, #B9B8B8 1.42%, #DBD6D6 11.75%, #B9B8B8 25.28%, #B9B8B8 40.82%, #DFDBDB 55.86%, #B9B8B8 72.91%, #ECE8E8 84.94%, #B9B8B8 97.67%);
                border: 1px solid #FFFFFF;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                width: 238px;
                height: 40px;

                display: flex;
                align-items: center;
                justify-content: center;

                &_title {
                    @include teko-font;
                    color: black;
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 16px;
                };
            }

            &_details {
                display: flex;
                column-gap: 50px;

                @include media-breakpoint-down (sm) {
                    flex-direction: column;
                    row-gap: 20px;
                }

                &_item {
                    &_title {
                        font-size: 20px;
                        text-transform: uppercase;
                        font-weight: 500;
                        display: block;
                        margin-bottom: 5px;
                    }

                    &_star {
                        color: $brand-blue;
                        margin-right: 5px;
                    }

                    &_reviews {
                        color: #878A99;
                        font-size: 10px;
                        margin-left: 5px;
                    }

                    &_upvotes {
                        color: $brand-yellow;
                    }
                }
            }
        }
    }

    &_tabs {
        margin-top: 25px;

        &_items {
            display: flex;
            column-gap: 30px;
            border-bottom: 1px solid #8E7EA2;
            padding-bottom: 10px;

            @include media-breakpoint-down (sm) {
                column-gap: 10px;
            }

            &_item {
                cursor: pointer;

                &-active {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background: $brand-blue;
                    }
                }

                &_title {
                    font-size: 20px;
                    font-weight: 500;
                    text-transform: uppercase;
                    text-decoration: none;
                    color: white;

                    @include media-breakpoint-down (sm) {
                        font-size: 15px;
                    }
                }
            }
        }

        &_content {
            &_item {
                margin-top: 15px;
                padding-bottom: 30px;
            }
        }

        &_empty {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
            color: $brand-yellow;
        }

        &_reviews {
            &_item {
                background: #1F1226;
                border: 1px solid #362D42;
                border-radius: 10px;

                &_header {
                    padding: 15px;
                    padding-top: 20px;
                    padding-bottom: 0px;

                    &_title {
                        text-transform: capitalize;
                        font-size: 25px;
                        font-weight: 500;
                        text-decoration: none;
                        color: white;

                        &:hover {
                            color: $brand-yellow;
                        }
                    }

                    &_by {
                        display: flex;
                        margin-bottom: 5px;

                        @include media-breakpoint-down (sm) {
                            display: block;
                        }

                        &_title {
                            color: #8E7EA2;
                            margin-right    : 5px;
                        }

                        &_name {
                            display: flex;
                            align-items: center;

                            a {
                                margin-left: 5px;
                                color: $brand-blue;
                                text-decoration: none;

                                &:hover {
                                    color: #028fcc;
                                }
                            }
                        }
                    }

                    .star-ratings {
                        &_star {
                            font-size: 18px;
                        }
                    }
                }

                &_content {
                    padding: 15px;
                    padding-top: 0px;
                    border-bottom: 1px solid #351F41;

                    &_text {
                        font-size: 15px;
                        margin-bottom: 0px;
                    }
                }

                &_footer {
                    padding: 15px;
                    padding-top: 5px;
                    padding-bottom: 5px;

                    &_vote {
                        font-size: 15px;

                        &-up {
                            color: $brand-yellow;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        &_favourite-servers {
            &_item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                @include media-breakpoint-down (md) {
                    flex-direction: column;
                    align-items: baseline;
                    position: relative;
                }

                &_header {
                    display: flex;
                    column-gap: 15px;
                    flex: 1;

                    @include media-breakpoint-down (md) {
                        display: block;
                    }

                    &_title {
                        margin-bottom: 10px;
                        font-size: 25px;
                        font-weight: 600;
                        color: white;
                        text-decoration: none;

                        @include media-breakpoint-down (md) {
                            font-size: 20px;
                        }

                        &:hover {
                            color: $brand-yellow;
                        }
                    }

                    &_image {
                        position: relative;
                        width: 100px;
                        height: 100px;

                        @include media-breakpoint-down (md) {
                            width: 50px;
                            height: 50px;
                        }
                    }
                }

                &_content {
                    display: flex;
                    column-gap: 15px;

                    @include media-breakpoint-down (md) {
                        align-self: flex-end;
                        position: absolute;
                        top: 0px;
                    }

                    a {
                        height: initial;
                    }
                }
            }
        }
    }
}
