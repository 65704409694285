.providers {
    margin-top: 20px;

    &-register {
        margin-top: 0px;
    }

    &_text {
        display: flex;
        align-items: center;
        gap: 10px;
        @include jost-font;

        &::after {
            content: " ";
            display: block;
            width: 100%;
            height: 1px;
            background-color: #676767;
        }

        &::before {
            content: " ";
            display: block;
            width: 100%;
            height: 1px;
            background-color: #676767;
        }

        &-register {
            margin-top: 15px;
            margin-bottom: 20px;

            &::before,
            &::after {
                width: 60%;
            }
        }

        &_text {
            width: 100%;
            text-align: center;
            font-size: 18px;

            &-highlight {
                color: $brand-yellow;
            }
        }
    }

    &_provider {
        @include inter-font;
        border: none;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        height: 45px;
        width: 100%;
        transition: border-radius 0.5s ease-in-out;

        &-discord {
            background-color: #5865F2;
            color: white;
        }

        &:hover {
            cursor: pointer;
            filter: brightness(120%);
            border-radius: 0px;

            // TOOD; This is hacky, but it works for now
            .providers_provider_icon {
                // Rotate icon
                transform: rotate(360deg);
            }
        }

        &_icon {
            transform: rotate(0deg);
            transition: transform 0.5s ease-in-out;
        }
    }
}
