.menu {
    display: flex;
    column-gap: 40px;

    &_item {
        display: flex;
        align-items: center;
        cursor: pointer;

        &-active {
            a {
                color: $brand-yellow !important;
            }
        }

        a {
            text-decoration: none;
            color: white;
        }

        a:hover {
           color: var(--zx-default-blue-hover);
        }

        &_text {
            text-transform: uppercase;
            letter-spacing: 0.1em;
            font-weight: 500;
        }


        &_icon {
            font-size: 1.2em;
        }
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.mobile-menu {
    &_icon {
        font-size: 24px;
    }

    a {
        text-decoration: none;
        color: white;
    }

    &_content {
        width: 300px;
        height: 100%;
        position: fixed;
        left: -300px;
        top: 0px;
        background-color: $background-color;
        transition: all 0.5s ease-in-out;

        &-open {
            left: 0px;
        }

        &_inner {
            padding: 20px;
            height: 100%;
            overflow-y: auto;
        }
    }

    &_item {
        cursor: pointer;
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        &_text {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &_label {
                letter-spacing: 0.1em;
            }

            &_icon {
                font-size: 1.4em;
                transition: all 0.5s ease-in-out;

                &-active {
                    transform: rotate(180deg);
                }
            }
        }

        &_dropdown {
            padding-left: 15px;
            animation: dropdown-open 0.5s ease-in-out;

            @keyframes dropdown-open {
                0% {
                    opacity: 0;
                    transform: translateY(-10px);
                }
                100% {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }

            &_item {
                display: flex;
                align-items: center;
                gap: 5px;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }

        &-active {
            &_text {
                &_label {
                    a {
                        color: $brand-yellow;
                    }
                    color: $brand-yellow;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

/* New */

.dropdown_menu_item {
    margin-top: 5px;
    cursor: pointer;
    position: absolute;
    width: 100%;
    background-color: #1b1024;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.menu-border {
    border: 1px solid #333b43;
}

.dropdown_menu_item_child {
    margin-top: -30px; /* align to the parent menu. TODO: any consequences of this?*/
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: absolute;
    width: 100%;
    background-color: #1b1024;
    position: absolute;
    left: 100%;
    padding-inline-start: 0;
    border: 1px solid #333b43;
}

.menu_row_child {
    list-style-type: none;
    padding-inline-start: 0;
    padding-left: 20px;
}

.menu-item-container {
    position: relative;
}

li.menu-item {
    list-style-type: none;
    padding-left: 15px;
    padding-right: 5px;
}

.menu-link {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;
}

.menu-link:first-child {
    padding-top: 12px;
    padding-bottom: 12px;
}

.menu_row_child:hover {
    background-color: #2e2436
}

.menu-item:hover {
    background-color: #2e2436
}

ul.menu-dropdown {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

@media only screen and (max-width: 780px) {
    .dropdown_menu_item {
        opacity: 1;
    }
    .menu-item-container  {
      padding-right: 0;
      padding-bottom: 15px,
    }
    .mobile-menu_content {
        background-color: #1b1024;
    }
    .z-index-1 {
        z-index: 1;
    }
    .z-index-2 {
        z-index: 2;
    }
    .dropdown_menu_item_child {
        left: 0%;
        margin-top: 10px;
    }
    /* Disable href link on menu click for mobile */
    /*li.menu-item.a {
        pointer-events: none;
        cursor: default;
      }
    */
  }
