.side-bar {
    position: fixed;
    background: #212529;
    height: 100vh;
    width: 250px;
    transition: all 0.5s ease-in-out;
    z-index: 99;

    &_logo {
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    &_menu {
        padding-top: 20px;
        padding-left: 40px;
        padding-right: 40px;

        &_title {
            color: rgba(135, 138, 153, 0.7);
        }

        &_item {
            cursor: pointer;

            &:not(:first-child) {
                margin-top: 40px;
            }

            &_icon {
                display: flex;
                align-items: center;
                column-gap: 20px;
                font-size: 25px;
            }

            &-active,
            &:hover {
                color: $brand-blue;
            }

            &_text {
                font-weight: 600;
                font-size: 16px;

                &-active {
                    color: $brand-blue;
                }
            }

            &_submenu {
                margin-top: 20px;
                ul {
                    list-style: none;
                    padding-left: 15px;
                }

                &_item {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;

                    &_icon {
                        font-size: 10px;
                        margin-right: 10px;

                        &-active {
                            animation: blink 1s linear infinite;

                            @keyframes blink {
                                0% {
                                    opacity: 0;
                                }
                                50% {
                                    opacity: 1;
                                }
                                100% {
                                    opacity: 0;
                                }
                            }
                        }


                        &-live {
                            color: #b0ffb0;
                        }

                        &-pending {
                            color: $brand-yellow;
                        }

                        &-paused {
                            color: white
                        }

                        &-drafts {
                            color: #FF2F2F;
                        }
                    }

                    &_text {
                        color: white;
                        font-weight: 300;
                    }
                }
            }
        }
    }


    &_footer {
        display: none;
    }

    @include media-breakpoint-down(md) {
        left: -250px;

        &-open {
            left: 0px;
            width: 100%;
        }

        &_footer {
            display: block;
            position: absolute;
            bottom: 20px;
            text-align: center;
            width: 100%;

            p {
                margin-bottom: 0px;
            }
        }
    }
}
