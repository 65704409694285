// Colours
$background-color: #191919;
$brand-yellow: #FFCC00;
$brand-blue: #02B3FF;
$brand-blue-hover: #0395d3;
$danger-red: #FF2F2F;

// Text
$text-color-dark-grey: #b6b6b6;

$input-border-line-color: #533f64;

// Bootstrap overrides colors
$primary: #02B3FF;

// Fonts
$jost-family: "Jost", sans-serif;
$oxanium-family: "Oxanium", sans-serif;

// Set Bootstrap container widths
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1130px,
    xxl: 1130px
);
