.reset-password,
.forgot-password {
    padding-top: 50px;

    &_header {
        font-family: $oxanium-family;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 0.03em;
        color: #FFFFFF;
        margin-bottom: 20px;

        &-highlight {
            background: linear-gradient(180deg, #02F0FF 0%, #0057FF 100%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }

    &_button {
        margin-top: 20px;
        width: 100%;
    }

    &_image {
        display: flex;
        justify-content: center;
        position: relative;
        top: -70px;

        @include media-breakpoint-down(lg) {
            left: 40px;
            top: -69px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &_forgot-password {
        margin-top: 10px;
    }
}
