.star-ratings {
    &_star {
        font-size: 24px;

        &-grey {
            color: #B3B3B3;
        }

        &-blue {
            color: #00C0FE;
        }
    }
}
