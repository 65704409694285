.home-listing-title {
    &_content {
        &_title {
            color: white;
            font-weight: bold;
        }

        &_text {
            color: #E6E6E6;
        }
    }
}
