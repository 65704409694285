.privacy-banner,
.terms-conditions-banner {
    min-height: 291px;
    width: 100%;
    background-image: url('/images/banners/terms-and-conditions.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: "";
        height: 100%;
        width: 100%;
        height: 291px;
        opacity: 0.6;
        background: #0A040E;
        position: absolute;
        z-index: -1;
        top: 0px;
    }

    &::before {
        content: "";
        height: 100%;
        width: 100%;
        height: 291px;
        position: absolute;
        z-index: -1;
        top: 0px;
        background: linear-gradient(180deg, rgba(29, 16, 36, 0) -5.31%, #211428 42.9%);
        opacity: 0.5;
    }

    &_content {
        &_header {
            font-family: $oxanium-family;
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 38px;
            color: white;

            &-terms {
                background: linear-gradient(180deg, #02F0FF 0%, #0057FF 100%);
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }
    }
}

.privacy-banner {
    background-image: url('/images/banners/privacy.png');
}
