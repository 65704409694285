.save-news {
    padding: 20px;
    padding-top: 40px;
    height: 56px;
    height: 100%;


    .spinner-border {
        width: 3rem;
        height: 3rem;
    }

    &_loader {
        display: flex;
        justify-content: center;
    }

    &_header {

        font-size: 20px;
        line-height: 29px;
        font-weight: 600;
        color: white;
    }

    &_image {
        background: #202328;
        border: 1px solid #533F64;
        border-radius: 4px;
        display: flex;
        max-width: 360px;
        height: 56px;

        &.is-invalid {
            border: 1px solid #dc3545;
        }

        &.is-valid {
            border: 1px solid #198754;
        }

        &_choose-image,
        &_image-preview {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-basis: 50%;
        }

        &_choose-image {
            background: #02B3FF;
            border-radius: 4px 0px 0px 4px;
            cursor: pointer;
        }
    }

    &_buttons {
        display: flex;
        justify-content: flex-end;
        column-gap: 20px;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }
}

.news {
    padding: 30px;
    padding-top: 40px;

    .spinner-border {
        width: 3rem;
        height: 3rem;
    }

    &_loader {
        display: flex;
        justify-content: center;
    }

    &_dropdown {
        a {
            text-decoration: none;
            color: $brand-blue;

            display: flex;
            align-items: center;
            column-gap: 5px;
        }
    }
}
