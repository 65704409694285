.addon-card,
.server-card {
    @include inter-font;
    background: #1B1024;
    border: 1px solid rgba(142, 126, 162, 0.3);
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.39);
    border-radius: 4px;
    word-wrap: break-word;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;

    &:hover {
        -webkit-filter: brightness(110%);
        filter: brightness(110%);
        transform: scale(1.03);
    }

    &_image {
        height: 100%;

        &_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 100%;

            &:hover {
                @include transition-brightness-122;
            }

            &_image {
                object-fit: cover;
            }
        }

        a {
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &-featured {
        border: 1px solid $brand-blue;
    }

    &_content {
        padding-top: 10px;
        padding-bottom: 10px;
        &_header {
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
                flex-direction: column-reverse;
            }

            &_title {
                position: relative;

                &_title {
                    cursor: pointer;
                    h2 {
                        font-size: 24px;
                        line-height: 29px;
                        text-transform: uppercase;
                        margin-bottom: 0px;
                        font-weight: 700;
                        display: inline;

                        @include media-breakpoint-down(md) {
                            display: flex;
                            align-items: center;
                            text-align: center;
                            justify-content: space-between;
                            flex-direction: column-reverse;
                        }

                        a {
                            text-decoration: none;
                            color: inherit;
                        }

                        i {
                            margin-left: 6px;
                            vertical-align: 3px;
                        }
                    }

                    &_text {
                        display: block;
                        margin-bottom: 10px;
                    }
                }

                &_icon {
                    vertical-align: baseline;
                }

                &_expansion {
                    display: none;

                    @include media-breakpoint-down(md) {
                        display: initial;
                    }
                }
            }

            &_expansion {
                padding-right: 10px;

                &-addon {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    align-content: flex-start;
                    gap: 10px;
                }


                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

        &_details {
            margin-top: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @include media-breakpoint-up(lg) {
                column-gap: 30px;
            }

            @include media-breakpoint-down(lg) {
                flex-wrap: wrap;
            }

            @include media-breakpoint-down(md) {
                justify-content: center;
                flex-direction: column;
            }

            &_info {
                font-weight: 500;
                line-height: 19px;

                display: flex;
                column-gap: 10px;
                align-items: center;
                flex-wrap: 30px;

                @include media-breakpoint-down(md) {
                    column-gap: 40px;
                }

                @include media-breakpoint-down(lg) {
                    margin-top: 5px;
                }

                &-bold {
                    font-weight: 700;

                    @include media-breakpoint-down(xl) {
                        display: block;
                    }
                }
            }

            &_population {
                display: flex;
                align-items: center;

                &_icon {
                    color: #00C0FE;
                    font-size: 22px;
                    margin-right: 5px;
                }
            }


            &_stars {
                margin-top: -2px;

                @include media-breakpoint-down(md) {
                    margin-top: 5px;
                    margin-bottom: 10px;
                }

                &_star {
                    font-size: 24px;

                    &-grey {
                        color: #B3B3B3;
                    }

                    &-blue {
                        color: #00C0FE;
                    }
                }
            }
        }

        &_features {
            font-size: 14px;
            line-height: 17px;
            margin-top: 20px;
            display: flex;
            column-gap: 10px;
            flex-wrap: wrap;
            row-gap: 10px;

            @include media-breakpoint-down(md) {
                justify-content: center;
            }

            &_feature {
                background: rgba(0, 192, 254, 0.15);
                border-radius: 32px;
                color: var(--zx-default-white);
                padding: 10px;
                text-align: center;
                border-color: #1B1024
            }
        }

        &_divider {
            color: #4D4D4D;
            margin-top: 5px;
            margin-bottom: 10px;
        }

        &_description {
            margin-bottom: 15px;
            font-weight: 400;
        }

        &_action {
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                height: 40px;
            }

            span {
                margin-right: 20px;
            }
        }
    }
}

.server-no-results {
    @include inter-font;
    background: #1B1024;
    border: 1px solid rgba(142, 126, 162, 0.3);
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.39);
    border-radius: 4px;
    word-wrap: break-word;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;

    h2 {
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        margin-bottom: 0px;
        font-weight: 700;
        display: inline;
        color: $brand-yellow;
    }

    p {
        margin-bottom: 0px;
    }
}
