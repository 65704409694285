img.profile-img {
    border-radius: 50%;
    width: 192px;
    height: 192px;
}

.username-title {
    font-weight: 600;
}

.rank-card {
    background-color: #8c9ba2;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    color: #000000;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 134px;
    max-height: 36px;
}
