.page-not-found {
    padding: 40px;
    padding-top: 10px;

    &_image {
        display: flex;
        justify-content: center;
    }

    &_header {
        text-align: center;

        h2 {
            color: $brand-yellow;
        }
    }
}
