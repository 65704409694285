.editor {
    border: 1px solid #362e42;

    &:focus-visible,
    &:focus {
        border-color: #dc3545;
        box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
    }

    &_menu {
        padding: 10px;
        background: #191919;
        border-radius: 3px 3px 0px 0px;
        border: 1px solid #362e42;

        &-invalid {
            border-color: #dc3545;
        }

        button {
            color: #4f555a;
            background: transparent;
            border: none;
            font-weight: bold;

            &.is-active {
                color: white;
            }
        }
    }

    &-invalid {
        border-color: #dc3545;
    }
}

.ProseMirror {
    min-height: 200px;
    padding: 15px;

    &-focused {
        border-color: #dc3545;
        box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
    }
}
