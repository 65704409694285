.footer {
    background: #0F0414;
    min-height: 370px;
    opacity: 0.97;

    &_logo {
        display: flex;
        justify-content: center;
        font-size: 40px;
    }

    &_sections {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    &_section {
        flex: 1 1 150px;

        &_header {
            margin-bottom: 15px;

            &_title {
                line-height: 29px;
                text-align: justify;
                color: #FFFFFF;
                text-transform: uppercase;
            }
        }

        &_content {
            &_list {
                padding: 0px;

                &_item {
                    list-style: none;
                    margin-bottom: 5px;

                    a {

                        color: #FFFFFF;
                        text-decoration: none;

                        &:hover {
                            color: var(--zx-default-blue-hover);
                        }
                    }
                }
            }
        }
    }
}
