.home-banner {
    min-height: 600px;
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::after {
        content: "";
        height: 100%;
        width: 100%;
        height: 600px;
        opacity: 0.8;
        background: #0A040E;
        position: absolute;
        z-index: -1;
        top: 0px;
    }

    .underline-custom {
        background:
           linear-gradient(90deg, currentColor calc(100%/3), transparent 0 calc(200%/3), currentColor 0)
           var(--d, 0%) 100% /300% 3px
          no-repeat;
        transition:0.5s;
      }
      .underline-custom:hover {
        --d: 100%;
      }

    &_content {
        z-index: 1;
        position: relative;
        &_inner {
            padding-top: calc(70px + 70px);

            @include media-breakpoint-down(lg) {
                padding-top: calc(70px + 20px);
            }
        }

        &_header {
            font-family: $oxanium-family;
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 62px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            background: linear-gradient(180deg, #00FFE0 14.29%, #0057FF 100%);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            margin-bottom: 0px;

            @include media-breakpoint-down(lg) {
                font-size: 40px;
                text-align: center;
                line-height: initial;
            }
        }

        &_subheader,
        &_paragraph {
            color: white;
        }

        &_subheader {
            font-family: $jost-family;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 43px;
            letter-spacing: 0.3em;
            text-transform: uppercase;

            @include media-breakpoint-down(lg) {
                font-size: 20px;
                text-align: center;
                line-height: initial;
                margin-bottom: 40px;
            }
        }

        &_paragraph {
            font-family: $jost-family;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            width: 60%;

            @include media-breakpoint-down(lg) {
                font-size: 20px;
                text-align: center;
                line-height: initial;
                font-size: initial;
                width: 100%;
                margin-bottom: 40px;
            }
        }

        &_actions {
            display: flex;
            gap: 2%;

            @include media-breakpoint-down(lg) {
                justify-content: center;
            }
        }

        &_images {
            position: relative;

            &_king,
            &_windrunner {
                position: absolute;
                z-index: 1;
            }

            &_king {
                width: 343px;
                height: 403px;
                top: -69px;
                left: -90px;
            }

            &_windrunner {
                width: 290px;
                height: 391px;
                top: -61px;
                left: 156px;
            }

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        min-height: 500px;
    }
}
