.home-detailed-text-section {
    @include media-breakpoint-up(sm) {
        margin-top: 20px;
    }

    .detailed-title {
        text-transform: capitalize;
    }

    .container img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    .image-container {
        position: relative;
        height: 100%;
        width: 100%;
    }

    &_image {
        &-elf {
            @include media-breakpoint-up(lg) {
                width: 75% !important;
            }
        }
    }
}
