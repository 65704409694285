.zx-text-gradient-highlight {
    background: linear-gradient(180deg,#02f0ff,#0057ff);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.zx-font-oxanium {
    @include oxanium-font;
    font-size: inherit;
}

.zx-btn-blue {
    background-color: var(--zx-default-blue);
}

.zx-btn {
    border: 1px solid var(--zx-default-hover);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    text-transform: var(--zx-btn-text-transform);
    font-size: var(--zx-btn-font-size);
    font-family: var(--zx-btn-font-type);
    /*font-weight: 500;*/
    cursor: pointer;
    text-align: center
}

.zx-btn-custom-padding {
    padding: 6px 34px 6px 34px;
}

.zx-btn-yellow {
    background-color: var(--zx-default-yellow);
}

.zx-btn-yellow:hover {
    background-color: var(--zx-default-yellow-hover);
}

.zx-btn-blue:hover {
    background-color: var(--zx-default-blue-hover);
}

.zx-transform-scale-11:hover {
    transform: scale(1.1);
}

.zx-transform-scale-12:hover {
    transform: scale(1.2);
}

.zx-transform-scale-13:hover {
    transform: scale(1.3);
}

.zx-brightness-110:hover {
    -webkit-filter: brightness(110%);
    filter: brightness(110%);
}

.zx-brightness-120:hover {
    -webkit-filter: brightness(120%);
    filter: brightness(120%);
}

.zx-brightness-130:hover {
    -webkit-filter: brightness(130%);
    filter: brightness(130%);
}

.zx-transition-03 {
    transition: all 0.3s ease-in-out;
}

.zx-transition-05 {
    transition: all 0.5s ease-in-out;
}

.zx-transition-07 {
    transition: all 0.7s ease-in-out;
}

.zx-transition-and-brightness-122 {
    -webkit-filter: brightness(122%);
    filter: brightness(122%);
    transition: all 0.3s ease-in-out;
}

.zx-text-color-blue {
    color: var(--zx-default-blue);
}

.zx-text-color-yellow {
    color: var(--zx-text-color-yellow);
}

.zx-text-color-white {
    color: var(--zx-default-white);
}

.zx-text-color-black {
    color: var(--zx-default-black);
}
.zx-text-color-black:hover {
    color: var(--zx-default-black);
}

.zx-text-link {
    color: #00C0FE;
    text-decoration: none;
}

.zx-text-link-white {
    color: var(--zx-default-white);
    text-decoration: none;
}

a.a-underline-none {
    text-decoration: none;
}

.zx-text-color-light-blue {
    color: #00C0FE;
}

.zx-text-color-grey {
    color: var(--zx-text-color-grey);
}

.zx-text-color-dark-grey {
    color: var(--zx-text-color-dark-grey);
}
