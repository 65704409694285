.auth-layout {
    background-color: $background-color;
    background-size: cover;
    background-image: url('/images/mountain-background.png');
    background-repeat: repeat;
    position: relative;
    z-index: 1;
    overflow: hidden;

    padding-top: 70px;
    padding-bottom: 70px;

    &::after {
        content: "";
        height: 100%;
        width: 100%;
        background: rgba(20, 8, 23, 0.91);
        position: absolute;
        z-index: -1;
        top: 0px;
    }
}
