.copy-right {
    height: 60px;
    background: linear-gradient(90deg, #190424 38.4%, #00408A 100%);
    color: white;
    font-family: $jost-family;

    &_inner {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
