.addon-gallery {
    &_title {
        @include oxanium-font;
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 30px;

        text-align: center;
        text-transform: capitalize;
        color: $brand-yellow;
    }

    &_list {
        display: flex;
        column-gap: 30px;

        &_item {
            max-width: 358px;

            &_image {
                position: relative;
                width: 358px;
                height: 200px;

                &:hover {
                    @include transition-brightness-122;
                }
            }

            &_description {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                margin-top: 15px;

                color: #9D9D9D;
            }

            &_read-more {
                a {
                    color: $brand-yellow;
                    text-decoration: none;
                    font-weight: bold;
                }
            }
        }
    }
}
