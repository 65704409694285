.article-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_image {
        position: relative;
        height: 250px;
        border-radius: 5px;
        overflow: hidden;

        &:hover {
            @include transition-brightness-122;
        }
    }

    &_author {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_date {
            display: flex;
            column-gap: 10px;
            align-items: center;
            font-size: 14px;

            &_text {
                color: #B1B1B1;
            }

            &_icon {
                color: $brand-yellow;
            }
        }

        &_name {
            a {
                text-decoration: none;
                color: #B1B1B1;
                text-transform: capitalize;

                &:hover {
                    color: $brand-yellow;
                }
            }
        }
    }

    &_title {
        margin-top: 5px;

        &_link {
            color: white;
            text-decoration: none;
            font-size: 20px;
            margin-bottom: 0px;

            &:hover {
                color: $brand-yellow;
            }
        }
    }

    &_content {
        color: #9D9D9D;
        font-weight: 400;

        p {
            margin-bottom: 5px;
        }
    }

    &_footer {
        a {
            text-decoration: none;
            font-weight: 500;
            color: $brand-yellow;

            &:hover {
                color: $brand-blue;
            }
        }
    }
}