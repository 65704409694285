h2 {

    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
}

h3 {

    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
}

p {

}

span {
    font-family: inherit;
}
