@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "mixins/main";
@import "layouts/main";
@import "pages/main";
@import "components/main";
@import "utils/main";
@import "react-toastify/dist/ReactToastify.css";

.App {
    height: 100vh;
}

body {
    color: var(--zx-default-white);
    @include jost-font;
}

:root {
    --zx-default-blue-hover: #0395d3;
    --zx-default-blue: #02B3FF;
    --zx-default-yellow: #FFC702;
    --zx-default-yellow-hover: #d3a500;
    --zx-default-white: #FFFFFF;
    --zx-default-black: #20132B;
    --zx-btn-font-type: 'Teko';
    --zx-btn-font-size: 18px;
    --zx-btn-text-transform: uppercase;
    --zx-text-color-yellow: #FFCC00;
    --zx-text-color-grey: #E6E6E6;
    --zx-text-color-dark-grey: #b6b6b6;
}

.hr-custom-dark {
    border: 1px solid #676767;
}
.hr-custom-light {
    border: 1px solid var(--zx-default-white);
}

.hr-custom-purple {
    border: 0.5px solid #351F41;
    margin: 0;
}

.hr-custom-light {
    border: 1px solid var(--zx-default-white);
    margin: 0;
}

svg.iconify.vertical-bottom-svg {
    vertical-align: bottom;
}

.comment-link {
    color: #02F0FF;
}
.border-radius-50 {
    border-radius: 50%;
}
.w-35 {
    width: 35%;
}
.form-control[readonly].form-color {
    background-color: #1b1024;
}
.cursor-pointer {
    cursor: pointer;
}
.hover-blue-color:hover {
    color: #02B3FF;
}

.react-select-container .react-select__control {
    padding: 0px !important;
    filter: none;
}

.bg-submit-review {
    background-color: rgb(206, 98, 10);
}
.bg-submit-review:hover {
    background-color: #dc3545;
}
.star-ratings-pointer {
    cursor: pointer;
}

.comment-starter {
    position: fixed;
    display: block;
    z-index: 99;
    text-align: center;
    bottom: 80px;
    left: 15px;
}

.featured-icon-listing {
    position: relative;
}

/* Server review page start */
.featured-icon-listing:hover::before  {
    content: "Featured Server";
    display: block;
    position: absolute;
    top: -47px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    padding: 5px 8px 5px 8px;
    border-radius: 5px;
    font-size: 12px;
    line-height: 1.2;
    font-weight: bold;
    text-transform: capitalize;
    background-color: #1B1024;
    border: 1px solid #1B1024;
    border-radius: 5px;
}

.featured-icon {
    position: relative;
}

.featured-icon:hover::before {
    content: "Featured Server";
    display: block;
    position: absolute;
    top: -55px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
}

  /* Server review page end */

.line-height-15 {
    line-height: 15px;
}


.btn-dark-custom {
    background-color: #2d2d31;
    border-color: #2d2d31;
}

/* Related to requiring login / signup to add review */
@keyframes highlight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(20px);
    }
  }

.view-server_reviews_sign-in.animate {
    animation-name: highlight;
    animation-duration: 0.5s;
    animation-iteration-count: 5;
    animation-direction: alternate;
}

.menu-item.active {
    background-color: #2e2436;
}

.blog-detail a {
    text-decoration: none;
}

#expansion-description a {
    text-decoration: none;
}

.fs-7 {
    font-size: 0.85rem;
}

.fs-8 {
    font-size: 0.75rem;
}

.text {
    /* padding-bottom: calc(1.25 * 15px); */
}

@media (min-width: 275px) {
    .text-xs-center {
        text-align: center !important;
    }
    .text-xs-left {
        text-align: left !important;
    }
    .mb-xs-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-xs-0 {
        margin-left: 0rem !important;
    }
}
@media (min-width: 576px) {
    .text-sm-center {
        text-align: center !important;
    }
    .text-sm-left {
        text-align: left !important;
    }
    .mb-sm-1 {
        margin-bottom: 1rem !important;
    }
    .margin-right-screen576 {
        margin-right: 1rem !important;
    }
}
@media (min-width: 768px) {
    .text-md-center {
        text-align: center !important;
    }
    .text-md-left {
        text-align: left !important;
    }
    .mb-md-1 {
        margin-bottom: 1rem !important;
    }
}
@media (min-width: 992px) {
    .text-lg-center {
        text-align: center !important;
    }
    .text-lg-left {
        text-align: left !important;
    }
    .mw-50-lg {
        max-width: 50%
    }
}
@media (min-width: 1200px) {
    .text-xl-center {
        text-align: center !important;
    }
    .text-xl-left {
        text-align: left !important;
    }
    .ml-xl-1 {
        margin-left: 0.25rem !important;
    }
}
