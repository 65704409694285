.server-news-layout {
    background-color: $background-color;
    background-size: cover;
    background-image: url('/images/general-background.png');
    background-repeat: repeat;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::after {
        content: "";
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(29, 16, 36, 0) -5.31%, #211428 30%);
        opacity: 0.5;

        position: absolute;
        z-index: -1;
        top: 0px;
    }

    &_content {
        margin-top: 150px;
    }
}
