.edit-profile {
    padding: 30px;
    padding-top: 20px;
    padding-left: 20px;

    &_divider {
        border-bottom: 1px solid rgba(135, 138, 153, 0.5);
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &_header {

        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        color: #FFFFFF;
    }

    &_avatar {
        margin-top: 20px;
        display: flex;
        column-gap: 20px;
        align-items: center;

        &_image {
            width: 100px;
            height: 100px;

            img {
                width: 100%;
                border-radius: 50%;
            }
        }
    }

    &_form {
        margin-top: 30px;
    }
}
