.server-tabs {
    border-bottom: 1px solid #533F64;

    &_content {
        display: flex;

        &_tab {
            flex-basis: 120px;
            font-weight: 300;
            color: white;
            text-align: center;
            cursor: pointer;

            &-active {
                color: $brand-blue;
                border-bottom: 3px solid $brand-blue;
                font-weight: 500;
            }
        }
    }
}
