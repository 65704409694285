.article-sidebar {
    &_header {
        background-color: $brand-yellow;
        text-align: center;

        &_title {
            color: #190424;
            font-weight: 500;
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 0px;
        }
    }

    &_content {
        background: #362644;
        border: 1px solid #4D4D4D;
        border-radius: 4px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        padding: 10px;
        padding-top: 20px;

        &-no-padding {
            padding-top: 10px;
        }

        ul {
            list-style-type: none;
            padding: 0px;

            &.bullet-points {
                // Create blue circle for bullet points
                li {
                    &:before {
                        content: '';
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background-color: $brand-blue;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    a {
                        text-decoration: none;
                        color: white;

                        &:hover {
                            color: $brand-yellow;
                        }
                    }
                }
            }
        }

        &_search {
            &_form {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 10px;

                &_input {
                    background-color: transparent;
                    border: none;
                    padding: 10px;
                    width: 99%;
                    color: white;
    
                    &::-webkit-input-placeholder {
                        color: #fff;
                    }
                }
    
                &_icon {
                    color: #9b92a1;
                }

                &_button {
                    background: none;
                    border: none;
                }
            }
        }
    }
}

.article-latest-comments-list {
    li {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.article-latest-comments {
    &_title {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    &_comment {
        color: #B1B1B1;
        text-decoration: none;

        &:hover {
            color: $brand-yellow;
        }
    }
}

.article-no-results {
    @include inter-font;
    background: #1B1024;
    border: 1px solid rgba(142, 126, 162, 0.3);
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.39);
    border-radius: 4px;
    word-wrap: break-word;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;

    h2 {
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        margin-bottom: 0px;
        font-weight: 700;
        display: inline;
        color: $brand-yellow;
    }

    p {
        margin-bottom: 0px;
    }
}

.article-line {
    background-color: #777777;
    height: 1px;
    margin-bottom: 20px;
}