.top-nav-bar {
    border-bottom: 1px solid #FFCC00;
    height: 70px;
    position: absolute;
    width: 100%;
    z-index: 99;

    &_inner {
        height: 100%;
        display: flex;
        font-family: $jost-family;
        color: white;
        justify-content: space-between;
        align-items: center;
    }

    &_logo {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;

        @include media-breakpoint-down(sm) {
            width: 140px;
        }
    }
}
