.btn {
    border-radius: 4px;
    height: 45px;
    text-transform: uppercase;

    @include teko-font;

    &:hover {
        color: white;
    }

    &:focus,
    &:disabled {
        color: white;
    }

    &-primary {
        color: white;
        /* border: 1px solid #DEDEDE; */
    }

    &-warning {
        background-color: #FFC702;
        color: white;
    }
}

.portal-layout {
    .btn {
        height: 40px;
        min-width: 130px;
    }
}
