.invoices {
    padding: 30px;
    padding-top: 40px;

    &_header {
        h1 {
            font-size: 20px;
        }
    }
}
