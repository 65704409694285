.servers {
    padding: 30px;
    padding-top: 40px;

    .spinner-border {
        width: 3rem;
        height: 3rem;
    }

    &_loader {
        display: flex;
        justify-content: center;
    }

    &_featured,
    &_upgrade-featured {
        color: $brand-yellow;
        font-weight: bold;
        cursor: pointer;
        text-align: center;
    }

    &_status-type {
        text-transform: capitalize;
        &_icon {
            font-size: 10px;
            margin-right: 5px;

            &-live {
                color: #b0ffb0;
            }

            &-pending {
                color: $brand-yellow;
            }

            &-paused {
                color: white
            }

            &-draft {
                color: #FF2F2F;
            }
        }
    }

    &_dropdown {
        a {
            text-decoration: none;
            color: $brand-blue;

            display: flex;
            align-items: center;
            column-gap: 5px;
        }
    }
}
