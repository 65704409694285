.top-bar {
    &_header {
        background: #212529;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;

        &_title {
            font-size: 20px;
            font-weight: 600;
        }

        &_button {
            width: 150px;
            height: 35px !important;
        }

        &_dashboard {
            &_item {
                font-size: 12px;
                color: #878A99;
            }

            &_spacer {
                font-size: 12px;
                color: #878A99;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
}
