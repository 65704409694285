.article-carousel {
    margin-top: 30px;
    margin-bottom: 30px;

    &_title {
        @include oxanium-font;
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 30px;

        text-align: center;
        text-transform: capitalize;
        color: $brand-yellow;
    }

    &_list {
        display: flex;
        column-gap: 30px;

        &_item {
            max-width: 358px;
            &_image {
                position: relative;
                height: 200px;
            }

            &_description {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                margin-top: 15px;

                color: #9D9D9D;
            }

            &_read-more {
                a {
                    color: $brand-yellow;
                    text-decoration: none;

                    &:hover {
                        color: $brand-blue;
                    }
                }
            }
        }
    }
}