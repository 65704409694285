.server-news {
    &_image {
        position: relative;
        width: 100%;
    }

    &_title {
        margin-top: 40px;

        h1 {

            font-weight: 700;
            font-size: 24px;
            line-height: 35px;
            letter-spacing: 0.05em;
        }
    }

    &_splitter {
        border-bottom: 1px solid rgba(135, 138, 153, 0.5);
        margin-bottom: 20px;
        width: 100%;
    }

    &_author {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        column-gap: 20px;

        &_name {
            flex-basis: 100%;

            &_title {

                font-weight: 600;
                font-size: 16px;
                color: $brand-blue;
                line-height: 23px;
                margin-bottom: 0px;
            }

            &_subtitle {

                font-weight: 400;
                font-size: 16px;
                line-height: 23px;

                &_date {
                    float: right;

                    &_edit {
                        color: $brand-blue;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &_other-news {
        &_title {
            @include oxanium-font;
            font-weight: 700;
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 30px;

            text-align: center;
            text-transform: capitalize;
            color: $brand-yellow;
        }

        &_no-news {

            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            text-align: center;
            color: $brand-yellow;
            text-align: center;
            width: 100%;
            font-size: 16px;
        }

        &_list {
            display: flex;
            column-gap: 30px;

            &_item {
                max-width: 358px;
                &_image {
                    position: relative;
                    width: 358px;
                    height: 200px;
                }

                &_description {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    margin-top: 15px;

                    color: #9D9D9D;
                }

                &_read-more {
                    a {
                        color: $brand-yellow;
                        text-decoration: none;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    &_share {
        margin-bottom: 50px;

        &_title {
            font-weight: 600;
            font-size: 20px;
            line-height: 29px;
        }

        &_subtitle {
            font-size: 14px;
            line-height: 20px;
        }

        &_copy {
            max-width: 400px;
            background-color: transparent;
            margin-top: 10px;
        }
    }
}

.servers-news {
    padding-bottom: 40px;
    padding-top: 40px;

    &_header {
        &_title {
            font-weight: 600;
            font-size: 28px;
        }
    }

    &_content {
        &_item {
            padding: 15px;
            background: #1B1024;
            border: 1px solid rgba(142, 126, 162, 0.3);
            box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.39);
            border-radius: 4px;

            &_image {
                position: relative;
                width: 100%;
                border-radius: 10px;
                height: 100%;
                overflow: hidden;
                border-radius: 10px;
            }

            &_content {
                &_expansion {
                    display: flex;
                    justify-content: space-between;

                    @include media-breakpoint-down(sm) {
                        flex-direction: column;
                        text-align: center;

                        &_image {
                            margin-top: 10px;
                        }
                    }

                    &_text {
                        color: $brand-yellow;
                    }
                }

                &_header {
                    &_title {
                        font-size: 24px;
                    }

                    a {
                        text-decoration: none;
                        color: white;

                        &:hover {
                            color: $brand-yellow;
                        }
                    }
                }

                &_date {
                    display: flex;
                    justify-content: space-between;

                    &_avatar {
                        display: flex;
                        align-items: center;

                        &_text {
                            margin-left: 10px;
                            text-transform: capitalize;
                            text-decoration: none;
                            color: white;

                            &:hover {
                                color: $brand-yellow;
                            }
                        }
                    }

                    &_text {
                        color: #A6A6A6;
                    }
                }
            }
        }
    }
}
