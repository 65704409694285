.analytics {
    &_title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_header {
            font-size: 20px;
            margin-bottom: 25px;
        }
    }

    &_content {
        padding: 30px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;

        &_clicks {
            &_item {
                padding: 15px;
                background-color: #23262B;
                border-radius: 10px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 25px;
                }

                &_title {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    margin-bottom: 15px;

                    &_icon {
                        width: 10px;
                        height: 10px;
                        border: 2px solid $brand-blue;
                        border-radius: 50%;
                    }

                    &_text {

                        font-size: 20px;
                    }
                }

                &_value {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &_text {
                        @include inter-font;
                        font-size: 32px;
                    }

                    &_up-down {

                        color: white;
                        font-size: 18px;

                        &-up {
                            color: #25A73B;
                        }

                        &-down {
                            color: #FF2F2F;
                        }
                    }

                    &_date {

                        color: #878A99B2;
                        font-size: 15px;
                    }
                }
            }
        }

        &_chart {
            position: relative;
            margin-top: 50px;

            @include media-breakpoint-down(md) {
                margin-top: 25px;
            }

            &_title {
                display: flex;
                align-items: center;
                column-gap: 10px;

                &_text {
                    font-size: 20px;
                }

                &_icon {
                    width: 10px;
                    height: 10px;
                    border: 2px solid $brand-blue;
                    border-radius: 50%;
                }
            }

            &_header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &_dropdown {
                text-align: right;
                margin-bottom: 25px;
            }
        }

        &_general-chart {
            background-color: #23262B;
            margin-top: 50px;
            border-radius: 10px;
        }
    }
}
