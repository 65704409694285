.view-server-banner {
    min-height: 290px;
    width: 100%;
    background-image: url('/images/banners/server-banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        height: 100%;
        width: 100%;
        height: 291px;
        opacity: 0.6;
        background: #0A040E;
        position: absolute;
        z-index: -1;
        top: 0px;
    }

    &::before {
        content: "";
        height: 100%;
        width: 100%;
        height: 291px;
        position: absolute;
        z-index: -1;
        top: 0px;
        background: linear-gradient(180deg, rgba(29, 16, 36, 0) -5.31%, #211428 42.9%);
        opacity: 0.5;
    }
}
