.payment {
    padding: 20px;
    padding-top: 40px;

    &_header {
        &_title {
            font-size: 20px;
            line-height: 29px;
            font-weight: 600;
            color: white;
        }
    }

    &_button {
        margin-top: 20px;
    }

    &_methods {

        &_method {
            border: 0.5px solid #FFFFFF;
            border-radius: 4px;
            position: relative;
            cursor: pointer;
            height: 100%;

            &-disabled {
                cursor: initial;
            }

            &_body {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 10px;
                gap: 10px;

                &_text {
                    margin-bottom: 0px;
                }
            }

            &-selected {
                border: 1px solid $brand-yellow;
            }

            &_selected {
                background: #FFCC00;
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                right: -7px;
                font-size: 11px;
                position: absolute;
                border: 3px solid white;
                top: -8px;
            }
        }
    }

    &_info {
        background: #1E222D;
        padding: 15px;
        position: relative;
        margin-top: 17px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url(/images/portal/payment-methods/triangle.svg);
            top: -13px;
            height: 13px;
            left: -2px;
            background-repeat: repeat;
        }

        &::before {
            bottom: -11px;
            top: initial;
            transform: rotate(180deg);
            left: 1px;
        }

        &_title {
            font-size: 20px;
        }

        &_body {
            &_list {
                list-style: none;
                padding: 0px;
            }

            li {
                display: flex;
                justify-content: space-between;
            }

            &_text {
                color: #7A7A7A;

                &-price {
                    color: #C1B7B7;
                }
            }
        }
    }

}

.payment-success {
    padding: 20px;
    padding-top: 40px;

    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    top: -125px;

    &_text {
        margin-top: -85px;
        text-align: center;
    }
}

.payment_info_body_text.note {
    font-size: 12px;
    line-height: 0;
}
