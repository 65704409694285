.table {
    background-color: #23262B;
    color: white;


    thead {
        th {
            font-weight: 700px;
            color: #878A99;
            font-size: 14px;
        }
    }

    > :not(:first-child) {
        border-top: 2px solid #A6A6A6;
    }

    > :not(caption) > * > * {
        border-bottom-width: 0px;
    }
}
