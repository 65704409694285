.general-layout {
    &_nested {
        background-color: $background-color;
        position: relative;
        z-index: 1;

        &_background {
            height: 100%;
            width: 100%;
            position: absolute;
            overflow: hidden;
            z-index: 2;
        }

        &_content {
            z-index: 10;
            position: relative;
        }

        &::after {
            content: "";
            height: 100%;
            width: 100%;
            background: linear-gradient(180deg, rgba(29, 16, 36, 0) -5.31%, #211428 0.9%);
            opacity: 0.7;
            position: absolute;
            z-index: -1;
            top: 0px;
        }

        &_planet-right,
        &_planet-bottom,
        &_planet-left {
            position: absolute;
            z-index: -1;
        }

        &_planet-right {
            right: 0px;
        }

        &_planet-left {
            bottom: 20%;
        }

        &_planet-bottom {
            bottom: -105px;
            right: 0px;
        }

        &_flare-2,
        &_flare-1 {
            position: absolute;
            z-index: -1;
        }

        &_flare-1 {
            bottom: 40%;
            right: -20px;
        }

        &_flare-2 {
            bottom: 0px;
        }
    }
}
