.hero-banner {
    min-height: 291px;
    width: 100%;
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-between(xs, md) {
        min-height: 200px;
    }

    &::after {
        content: "";
        height: 100%;
        width: 100%;
        height: 291px;
        opacity: 0.5;
        background: #0A040E;
        position: absolute;
        z-index: -1;
        top: 0px;
    }

    &::before {
        content: "";
        height: 100%;
        width: 100%;
        height: 291px;
        position: absolute;
        z-index: -1;
        top: 0px;
        background: linear-gradient(180deg, rgba(29, 16, 36, 0) -5.31%, #211428 42.9%);
        opacity: 0.5;
    }

    &_content {
        padding-top: 70px;
        z-index: 1;

        &_header {
            font-family: $oxanium-family;
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 38px;
            color: white;

            @include media-breakpoint-down(md) {
                font-size: 22px;
                line-height: 31px;
            }

            &-terms {
                background: linear-gradient(180deg, #02F0FF 0%, #0057FF 100%);
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }
    }
}
