.ad-layout {
    .banner-wrapper {
        position: relative;
        border: 1px solid #02C2FF;
        width: 100%;
        height: 140px;
        transition: all 0.3s ease-in-out;
    }
    .banner-wrapper:hover {
        -webkit-filter: brightness(145%);
        filter: brightness(145%);
    }
    .container img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    @media only screen and (max-width: 780px) {
        .banner-wrapper {
            height: 80px;
        }
    }
}

