.portal-top-nav-bar {
    background: #292E32;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 99;

    &_profile {
        background: #31373C;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 265;
        padding: 20px;

        &_content {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 12px;
        }

        &_name {
            font-size: 14px;

            &_name,
            &_email {
                margin-bottom: 0px;
            }

            &_name {
                line-height: 18px;
            }

            &_email {
                color: #878A99;
                font-size: 12px;
            }
        }
    }

    &_burger {
        display: none;
    }

    @include media-breakpoint-down(md) {
        height: 75px;
        position: fixed;
        top: 0px;

        &_burger {
            display: flex;
            align-items: center;
            padding: 20px;

            svg {
                height: 50px;
                width: 25px;
            }
        }
    }
}
