.feature-card {
    background: #1B1024;
    border: 1px solid rgba(142, 126, 162, 0.3);
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.39);
    border-radius: 4px;
}

svg.vertical-align-top.iconify {
    vertical-align: text-top;
}

button.btn-feature-color {
    background-color: unset;
    border: 1px solid #533F64;
    border-radius: 4px;
}

span.vertical-align-sub {
    vertical-align: sub;
}

.feature-first {
    border: 1px solid #B6B5B6;
    border-radius: 4px;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 4rem;
    padding-right: 4rem;
}

.feature-blue {
    background-color: var(--zx-default-blue);
}

.feature-purple {
    background-color: #5C4369;
}
